import type { FC, MutableRefObject } from 'react';
import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { OperationalEvent } from '@confluence/analytics';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { VIEW_PAGE } from '@confluence/named-routes';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import type { SpaceShortcutsSpaceNavigationQuery_spaceSidebarLinks_quick as SpaceNavigationQueryShortcutsType } from './__types__/SpaceShortcutsSpaceNavigationQuery';
import { DraggableShortcutsItem } from './DraggableShortcutsItem';
import { useDeleteShortcut, useMoveShortcut } from './useShortcuts';

type ShortcutsDragContainerProps = {
	isSpaceAdmin: boolean;
	links: SpaceNavigationQueryShortcutsType[];
	spaceId: string | null;
	spaceKey: string;
	shortcutsHistoryRef: MutableRefObject<any>;
	handleResolvedLinks: (key: any) => void;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShortcutsListContainer = styled.ul({
	margin: 0,
	padding: 0,
	'list-style': 'none',
});

const ShortcutsDragContainerComponent: FC<ShortcutsDragContainerProps & WithFlagsProps> = memo(
	({ flags, isSpaceAdmin, links, spaceId, spaceKey, shortcutsHistoryRef, handleResolvedLinks }) => {
		const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
		const { createAnalyticsEvent } = useAnalyticsEvents();
		const { moveShortcut, undoMoveShortcut } = useMoveShortcut(links, flags, shortcutsHistoryRef);
		const { deleteShortcut, undoDeleteShortcut } = useDeleteShortcut(
			links,
			flags,
			shortcutsHistoryRef,
		);

		const hideLastUndoFlag = () => {
			// hides previous success flag so that only one is on the screen at a time
			const lastMovement = shortcutsHistoryRef.current.peek();
			if (lastMovement) {
				void flags.hideFlag(lastMovement.flagId);
			}
		};

		const onDeleteShortcut = (item) => {
			hideLastUndoFlag();
			deleteShortcut(item);
			shortcutsHistoryRef.current.push({
				undo: () => undoDeleteShortcut(item),
				// makes the confirmation flag display the message reflecting only the latest change
				lastDeletedItem: item,
			});
		};

		const onDragStart = async () => {
			// hides the success flag when moving a subsequent shortcut
			hideLastUndoFlag();
		};

		const onDragEnd = async (source, destination) => {
			hideLastUndoFlag();
			moveShortcut(source, destination);
			shortcutsHistoryRef.current.push({
				undo: () => {
					undoMoveShortcut(source);
					createAnalyticsEvent({
						type: 'sendTrackEvent',
						data: {
							action: 'undo',
							actionSubject: 'update',
							actionSubjectId: 'spaceShortcuts',
							source: 'viewPageScreen',
							attributes: {
								previousRank: source,
								newRank: destination,
								action: 'reorder',
							},
						},
					}).fire();
				},
			});
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'updated',
					actionSubject: 'spaceShortcuts',
					source: 'viewPageScreen',
					attributes: {
						previousRank: source,
						newRank: destination,
						action: 'reorder',
					},
				},
			}).fire();
		};

		const displayLinks = useMemo(() => Array.from(links).reverse(), [links]);
		const pageIds = displayLinks
			.filter((item) => (item.styleClass || '').indexOf('external_link') < 0)
			.filter((item) => VIEW_PAGE.match(item.url || ''))
			.map((item) => VIEW_PAGE.match(item.url || '')?.params.contentId);

		return (
			<ShortcutsListContainer data-vc="shortcuts-drag-container" {...ssrPlaceholderIdProp}>
				{displayLinks.map((item, index) => (
					<DraggableShortcutsItem
						key={`shortcuts-item-${item?.id || index}`}
						item={item}
						index={index}
						spaceId={spaceId}
						createAnalyticsEvent={createAnalyticsEvent}
						isSpaceAdmin={isSpaceAdmin}
						links={links}
						deleteShortcut={onDeleteShortcut}
						onDragStart={onDragStart}
						onDragEnd={onDragEnd}
						handleResolvedLinks={handleResolvedLinks}
					/>
				))}

				<OperationalEvent
					id={spaceKey}
					action="initialized"
					actionSubject="spaceShortcutsSection"
					source="dashboard"
					containerType="space"
					containerId={spaceId!}
					attributes={{
						navVersion: '3',
						numOfShortcuts: links.length,
						pageIds,
					}}
					// @ts-expect-error FIXME: this is ignored as LEGACY after migration
					createAnalyticsEvent={createAnalyticsEvent}
				/>
			</ShortcutsListContainer>
		);
	},
);

export const ShortcutsDragContainer = withFlags(ShortcutsDragContainerComponent);
